import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {

  current = this.router.stateService.current;
  title:string;
  icon:string;
  update:boolean;
  @Output() edit = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<boolean>();
  @Input() saved: boolean;
  @Input() valid: boolean;

  constructor(private router: UIRouter) { }

  ngOnInit() {
    //console.log(this.saved,'saved')
    switch(this.current.name) {
      case 'app.coffee':
        this.title = 'Coffee';
        this.icon = 'coffee';
        break;
      case 'app.products':
      case 'app.products.milk':
      // case 'app.products.papers':
      // case 'app.products.equipment':
      case 'app.products.other':
          this.title = 'Products';
          this.icon = 'product';
          break;
      case 'app.orders':
        this.title = 'Order History';
        this.icon = 'order';
        break;

      case 'app.success':
          this.title = 'Order Success'
          this.icon = 'order'
          break
      case 'app.update':
          this.title = 'Order Updated'
          this.icon = 'order'
          break
      case 'app.cancel':
            this.title = 'Order Cancelled'
            this.icon = 'order'
            break
      default:
        this.title = 'Profile';
        this.icon = 'user';
    } 
  }

  onClick() {
    this.update = !this.update;
    this.edit.emit(this.update);
  }

  onSave() {
    this.save.emit(true);
    this.edit.emit(false);
    this.update = false;
  }

  getCurrent(){
    if(this.current.name=='app.profile'){
      return true;
    }else{
      return false;
    }
  }

}
