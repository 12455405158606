import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment'
import { tap } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  private _profile:BehaviorSubject<any> = new BehaviorSubject({})

  public fetchProfile(){

    return this.http.get(environment.api+'/customer').pipe(tap((value:any)=>{
      value.customer.orders = value.customer.orders.edges
      value.customer.draftOrders = value.draftOrders.edges
      this.profile = value.customer
    })).subscribe()

  }  

  set profile(value){
    this.profile.next(value)
  }

  get profile(){
    return this._profile
  }


  public updateProfile(customer){

    // customer = object, same as object coming back from graphql

    this.http.put(environment.api+'/customer', {
      customer: customer
    }).subscribe((result:any)=>{
      this.profile = result.customerUpdate.customer      
    })

  }  


  public selectDefaultAddress(id){

    // customer = object, same as object coming back from graphql

    this.http.put(environment.api+'/customer/default-address', {
      id: id
    }).subscribe((result:any)=>{
      this.profile = result.customerUpdateDefaultAddress.customer
    })

  }  

}
