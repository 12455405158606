// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'https://nzkzt93rz0.execute-api.eu-west-1.amazonaws.com/latest',
  //api: 'http://localhost:3000',
  shopify: '22206c889df2eea4225973166a2ef84c',
  membrs: {
    login: 'https://signin.whitestar.coffee',
    api: 'api.memb.rs',
    apiProtocol: 'https://',
    graphQL: 'https://api-de-dev.memb.rs/graphql',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
