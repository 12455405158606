import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { CartService } from '../cart.service';
import { NgBusyService } from '@nakedcreativity/ng-busy';
import * as _ from 'underscore';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  constructor(private router: UIRouter, private cart: CartService, private busyService: NgBusyService) { }

  public current = this.router.stateService.current
  public products: any
  public allProducts: any
  public productTypes: any = []
  public productType: string;

  

  ngOnInit() {
    switch(this.current.name) {
        case 'app.coffee':
          this.productTypes = ['Coffee']
          break;
        default:
          this.productTypes = ['Milk Alternatives', 'Other']
      } 

      this.selectProductType(this.productTypes[0]) // select first one
  
  }

  selectProductType(type){

    this.productType = type
    // switch(this.current.name) {
    //   case 'app.coffee':
    //     this.collection = 'Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzcxNDQ0MDA0OTI3' // coffee
    //     break;
    //   default:
    //     this.collection = 'Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE2NTc5ODI4MTI3OQ==' //wholesale products
    // } 
    this.cart.fetchProducts(type).subscribe(value=>{
      this.products = value
    })

    // this.shopifyService.collection = this.collection

    
    // this.shopifyService.products.subscribe((result) => {
      
    //   this.products = collection.products
    //   this.allProducts = collection.products
    //   this.products.forEach(product=>{
    //     var productTypeExists = _.contains(this.productTypes, product.productType);
    //     if (productTypeExists == false){
    //       this.productTypes.push(product.productType)
    //     }
    //   })
    //   this.productType = this.productTypes[0];
    //   this.setType(this.productType)
    //   this.busyService.finished()
    // });

   
    


    this.cart.cartVisible = true;
    
    
  }

  // setType(type){
  //   const filteredProducts = Object.assign({}, this.allProducts);
  //   this.productType = type;
  //   this.products = _.where(filteredProducts, {productType: type});
  // }

}
