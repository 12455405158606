import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';

@Component({
  selector: 'app-update-success',
  templateUrl: './update-success.component.html',
  styleUrls: ['./update-success.component.scss']
})
export class UpdateSuccessComponent implements OnInit {

  constructor(private cartService: CartService) { }

  ngOnInit(): void {

    this.cartService.cartVisible = false
  }

}
