import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  @Input() public text;
  @Input() public title;
  @Input() public saveText;
  @Input() public cancelText;

  ngOnInit(): void {
  }

  save(){
    //console.log('save!')
    this.activeModal.close({
        action: 'save'
      });
  }

  cancel(){
    this.activeModal.close({
      action: 'cancel'
    });
  }

}
