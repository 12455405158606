import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UIRouter } from '@uirouter/angular';
import { MessageComponent } from '../message/message.component';
import { CartService } from '../cart.service'
import { ProfileService } from '../profile.service';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  constructor(private modalService: NgbModal,private cart: CartService,private router: UIRouter,private profileService:ProfileService) { }

  @Input() order: any;
  @Input() draft: boolean;

  ngOnInit() {
  }

  formatAddress(address){

    var lines = []

    if(address){

    address.company?lines.push(address.company):null
    address.address1?lines.push(address.address1):null
    address.address2?lines.push(address.address2):null
    address.city?lines.push(address.city):null
    address.zip?lines.push(address.zip):null
    address.country?lines.push(address.country):null
    }


    return lines.join(', ')
  }

  editModal(){
    const modalRef = this.modalService.open(MessageComponent, { centered: true})
    modalRef.result.then((value:any)=>{
      if(value.action == 'save'){
        var cartItems = [];
        var lineItems = this.order.node.lineItems.edges;
        lineItems.forEach((item:object)=>{
          var cartItem = {
            name: item['node'].title,
            total: parseFloat(item['node'].variant.price) * parseInt(item['node'].quantity),
            currency: 'GBP',
            variant: {
              id: item['node'].variant.id,
              name: item['node'].variant.title,
              price: item['node'].variant.price
            },
            qty: item['node'].quantity
          }
          cartItems.push(cartItem);
        })
        this.cart.edit(cartItems);
        this.cart.cartOrder = this.order['node'].id;
        this.cart.cartAddress = this.order['node'].shippingAddress;
        this.router.stateService.go('app.coffee')
      }
    });
    try{
      modalRef.componentInstance.title = 'Edit order?';
      modalRef.componentInstance.text = 'Editing this order will replace the current contents of your cart. Please place any orders in your cart before proceeding.';
      modalRef.componentInstance.saveText = 'Continue';
      modalRef.componentInstance.cancelText = 'Cancel';
    }catch{

    }
   }

   cancelModal(){
    const modalRef = this.modalService.open(MessageComponent, { centered: true})
    modalRef.result.then((value:any)=>{
      if(value.action == 'save'){
        this.cart.cancelOrder(this.order['node'].id).subscribe(result=>{
          // go away and fetch new orders
          this.profileService.fetchProfile()
          this.router.stateService.go('app.cancel')
        })
      }
    });
    try{
      modalRef.componentInstance.title = 'Cancel order?';
      modalRef.componentInstance.text = 'Are you sure you want to cancel this order.';
      modalRef.componentInstance.saveText = 'Yes';
      modalRef.componentInstance.cancelText = 'No';
    }catch{

    }
   }

}
