import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';

@Component({
  selector: 'app-cancel-success',
  templateUrl: './cancel-success.component.html',
  styleUrls: ['./cancel-success.component.scss']
})
export class CancelSuccessComponent implements OnInit {

  constructor(private cartService: CartService) { }

  ngOnInit(): void {

    this.cartService.cartVisible = false
  }

}
