import { Injectable } from '@angular/core';
import { CartItem } from './cart-item'
import { AddressItem } from './address-item'
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../environments/environment'
import { tap } from 'rxjs/operators';
import { env } from 'process';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private _cartItems: BehaviorSubject<CartItem[]> = new BehaviorSubject([])
  private _cartTotal: BehaviorSubject<number> = new BehaviorSubject(0)
  private _cartVisible: BehaviorSubject<boolean> = new BehaviorSubject(false)
  private _cartOrder: BehaviorSubject<string> = new BehaviorSubject(null)
  private _cartAddress: BehaviorSubject<AddressItem[]> = new BehaviorSubject([])
  private _lsKey: string = 'cartItems';
  private _lsOrder: string = 'cartOrder';
  private _lsAddress: string = 'cartAddress';

  constructor(private http: HttpClient) { 


    try {
      var items = JSON.parse(localStorage.getItem(this._lsKey))
      if(items && items.length > 0){
        this._cartItems.next(items)
      }
    }catch{

    }

    try {
      var order = JSON.parse(localStorage.getItem(this._lsOrder))
      if(order && order.length > 0){
        this._cartOrder.next(order)
      }
    }catch{

    }

    try {
      var orderAddress = JSON.parse(localStorage.getItem(this._lsAddress))
      //console.log(orderAddress,'order address')
      if(orderAddress && orderAddress.length > 0){
        this._cartAddress.next(orderAddress)
      }
    }catch{

    }

    this._cartItems.subscribe(value=>{

      var cartTotal = 0;
      value.forEach((item:CartItem)=>{
        cartTotal = cartTotal + item.total
      })
      this._cartTotal.next(cartTotal)
      localStorage.setItem(this._lsKey, JSON.stringify(value))


      // if cart items change
      if(value.length > 0){
        this.cartVisible = true
      }

    })

    this._cartOrder.subscribe(value=>{
      localStorage.setItem(this._lsOrder, JSON.stringify(value))
    })

    this._cartAddress.subscribe(value=>{
      localStorage.setItem(this._lsAddress, JSON.stringify(value))
    })



  }

  

  get cartItemsObservable(){
    //console.log(this.cartItems,'get cart items')
    return this._cartItems
  }

  get cartTotalObservable(){
    //console.log(this.cartTotal,'get cart total')
    return this._cartTotal
  }

  set cartOrder(id:string){
    this._cartOrder.next(id)
  }

  get cartOrderObservable(){
    return this._cartOrder
  }

  set cartAddress(address:any){
    var addressItem = [];
    //console.log(address)
    addressItem.push(address)
    this._cartAddress.next(addressItem)
  }

  get cartAddressObservable(){
    return this._cartAddress
  }

  set cartVisible(value:boolean){

    if(value === true && this._cartItems.value.length == 0){
      value = false
    }
    setTimeout(()=>{
      this._cartVisible.next(value)
    },0)
  }

  get cartVisibleObservable(){
    //console.log(this.cartVisible,'get cart visible')
    return this._cartVisible
  }

  edit(products:any){

    this._cartItems.next(products)

  }

  add(product: CartItem){
    var cartItems = this._cartItems.value
    cartItems.push(product)
    this._cartItems.next(cartItems)

  }

  remove(index){
    var cartItems = this._cartItems.value
    cartItems.splice(index, 1);
    this._cartItems.next(cartItems)
  }

  empty(){
    this._cartItems.next([])
  }

  updateQty(index,quantity){
    var cartItems = this._cartItems.value
    cartItems[index].qty = quantity;
    this._cartItems.next(cartItems);
  }

  placeOrder(address?: any){

    var lineItems = []
    this._cartItems.value.forEach((item:CartItem)=>{
      lineItems.push({
        quantity: item.qty,
        variantId: item.variant.id
      })
    })

    return this.http.post(environment.api+'/order', {
      lineItems: lineItems,
      shippingAddress: address
    }, {
      headers: this.headers
    }).pipe(tap((value:any)=>{
      // success, so empty cart
      this._cartItems.next([])
      this.cartVisible = false
    }))

  }

  updateOrder(){
    var lineItems = []
    this._cartItems.value.forEach((item:CartItem)=>{
      lineItems.push({
        quantity: item.qty,
        variantId: item.variant.id
      })
    })
    // console.log(this._cartAddress.value[0], 'cart items aadress')
    // console.log(this._cartOrder.value, 'cart order aadress')
    return this.http.put(environment.api+'/order/'+encodeURIComponent(this._cartOrder.value), {
      lineItems: lineItems,
      shippingAddress: this._cartAddress.value[0]
    }, {
      headers: this.headers
    }).pipe(tap((value:any)=>{
      // success, so empty cart
      this._cartItems.next([])
      this._cartOrder.next(null)
      this._cartAddress.next([])
      this.cartVisible = false
    }))

  }

  cancelOrder(id){
    //console.log('cancel')
    return this.http.delete(environment.api+'/order/'+encodeURIComponent(id), {
      headers: this.headers
    })
  }

  get headers(){
    return new HttpHeaders({
      authorization: localStorage.getItem('membrs')
    })
  }

  fetchProducts(type){
    return this.http.get(environment.api+'/products?type='+type)
  }
  


}
