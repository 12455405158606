import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { CartService } from '../cart.service'

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  constructor(private fb: FormBuilder, private cart: CartService) { }

  @Input() product: any;
  @Input() last: any;
  public expanded:boolean = false;
  public moreText:any;
  public productForm: FormGroup
  public totalPrice: number = 0

  ngOnInit() {
    // let group = {"qty": this.fb.control('',Validators.min(0))}
    // this.product.options.forEach(option=>{
    //   group[option.name]= this.fb.control('')
    // })
    this.productForm = this.fb.group({
      variant: this.fb.control(null, Validators.required),
      qty: this.fb.control('',[Validators.min(1), Validators.required])
    });

    this.productForm.valueChanges.subscribe(value=>{
      // console.log('value changes...')
      // console.log(value)
      if(value.qty && value.variant){
        this.totalPrice = parseFloat(this.price(value.variant.node)) * parseInt(value.qty)
      }

    })
    
    var paragraphs = this.product.descriptionHtml.split('</p>');
    paragraphs.splice(0,1);
    var text = paragraphs.join("");
    var regex = /\<p>/gi;
    var format = text.toString().replace(regex, "</p><p>");
    this.moreText = format.replace('</p>','')
  }

  getFirst(){
    var paragraphs = this.product.descriptionHtml.split('</p>');
    paragraphs.splice(1);
    return paragraphs[0]+'</p>';
  }

  addToCart(){
    this.cart.add({
      name: this.product.title,
      total: this.totalPrice,
      variant: {
        id: this.productForm.value.variant.node.id,
        name: this.productForm.value.variant.node.title,
        price: this.price(this.productForm.value.variant.node)
      },
      qty: this.productForm.value.qty
    })
  }

  price(variant){
    return variant.wholesalePrice?variant.wholesalePrice:variant.price
  }


}
