import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ProfileService } from '../profile.service';
import { CartService } from '../cart.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressComponent } from '../address/address.component';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private fb: FormBuilder, private profileService: ProfileService, private cartService: CartService, private modalService: NgbModal) { }

  public profileForm: FormGroup
  edit:boolean = false;
  saved:boolean = false;

  private _profile: any
  private subscriptions:Array<Subscription> = []

  ngOnInit() {

    this.profileForm = this.fb.group({
      email: this.fb.control('', [Validators.required,Validators.email]),
      phone: this.fb.control('', Validators.required),
      firstName: this.fb.control('', Validators.required),
      lastName: this.fb.control('', Validators.required),

    })

    this.subscriptions.push(this.profileService.profile.subscribe(result=>{
      //console.log('profile updated...')
      //console.log(result)
      this.profile = result
      this.profileForm.patchValue(this.profile)
    }))
    this.cartService.cartVisible = false
  }

  onEdit(arg: boolean) {
   //console.log(arg);
   this.edit = arg;
   if(arg == false){
     //reset from logic here
     //console.log('reset!')
     this.profileForm.patchValue(this.profile)
   }
  }

  onSave(arg: boolean) {
    this.saved = arg;
    if(arg == true){
      //save from logic here
      this.profileService.updateProfile(this.profileForm.value)
      this.edit = false
      this.saved = false
    }
   }

   set profile(value){
    this._profile = value
   }

   get profile(){
    return this._profile
   }

   ngOnDestroy(){
    this.subscriptions.forEach((item:Subscription)=>{
      item.unsubscribe()
    })
  }

  addressModal(index){
   // console.log(index)
    //console.log(this.profile.addresses[index])
    const modalRef = this.modalService.open(AddressComponent, { centered: true, size: 'lg'})
    
    modalRef.result.then((value:any)=>{
      //console.log('address value')
      //console.log(value)
      if(value.id){
        value.address.id = value.id
        this.profile.addresses[index] = value.address
        this.profileService.updateProfile(this.profile)
      }else {
        this.profile.addresses.push(value.address)
        this.profileService.updateProfile(this.profile)
      }


    });
    try{
      modalRef.componentInstance.address = this.profile.addresses[index];
    }catch{

    }
   }

   removeAddress(index){
      this.profile.addresses.splice(index,1)
      this.profileService.updateProfile(this.profile)
    
   }

   isDefault(id){
     return id == this.profile.defaultAddress.id
   }

   makeDefault(id){
     //console.log('make default',id)
     this.profileService.selectDefaultAddress(id)
   }

}
