import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import { Subscription } from 'rxjs';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit {

  public showCart = false
  public cartVisible:boolean = false
  private subscriptions:Array<Subscription> = []
  toggled:boolean = false;

  constructor(private cartService:CartService) { }
  
  ngOnInit() {

    console.log('layout init')
    this.subscriptions.push(this.cartService.cartVisibleObservable.subscribe(value=>{
      this.cartVisible = value
    }))

  }

  onToggle(arg: boolean) {
    //console.log(arg);
    this.toggled = arg;
   }

   onClick(arg: boolean) {
    //console.log(arg);
    if(arg == true){
      this.toggled = false;
    }
   }

  ngOnDestroy(){
    this.subscriptions.forEach((item:Subscription)=>{
      item.unsubscribe()
    })
  }

}
