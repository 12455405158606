import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import { CartItem } from '../cart-item';
import { ProfileService } from '../profile.service';
import { UIRouter } from '@uirouter/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  private _cart:Array<CartItem> = []
  private _total:number
  private _order:string
  private _orderAddress:any
  private _addresses:Array<any> = []// to-do add address interface
  private _selectedAddress:String = null


  public busy: boolean = false

  constructor(private cartService:CartService, private profileService:ProfileService, private router:UIRouter) { }

  ngOnInit() {
    this.cartService.cartItemsObservable.subscribe(value=>{
      this._cart = value
    })
    this.cartService.cartTotalObservable.subscribe(value=>{
      //console.log(value,'cart total service')
      this._total = value
    })
    this.cartService.cartOrderObservable.subscribe(value=>{
      //console.log(value,'cart total service')
      this._order = value
    })

    this.cartService.cartAddressObservable.subscribe(value=>{
      this._orderAddress = value
    })


    this.profileService.profile.subscribe(profile=>{
      if(profile.addresses)
        this._addresses = profile.addresses
      if(profile.defaultAddress)
        this._selectedAddress = profile.defaultAddress.id
    })

  


    
  }

  formatAddress(address){

    var lines = []

    if(address){

    address.company?lines.push(address.company):null
    address.address1?lines.push(address.address1):null
    address.address2?lines.push(address.address2):null
    address.city?lines.push(address.city):null
    address.zip?lines.push(address.zip):null
    address.country?lines.push(address.country):null
    }

    return lines.join(', ')
  }

  removeItem(i:number){
    //console.log(i,'item index');
    this.cartService.remove(i)
  }

  qtyMinus(i:number,qty:number){
    //console.log(i,'item index');
    var quantity = qty - 1;
    if(quantity <= 0){
      this.cartService.remove(i)
    }else{
      this.cartService.updateQty(i,quantity)
    }
  }

  qtyPlus(i:number, qty:number){
    //console.log(i,'item index');
    var quantity = qty + 1;
    this.cartService.updateQty(i,quantity)
  }

  placeOrder(){

    this.busy = true

    var selectedAddress:any = null
    this._addresses.forEach(address=>{
      if(address.id == this._selectedAddress){
        selectedAddress = address
      }
    })
    this.cartService.placeOrder(selectedAddress).subscribe(result=>{
      // go away and fetch new orders
      this.profileService.fetchProfile()
      this.busy = false
      this.router.stateService.go('app.success')
    }, error=>{
      alert(error.message)
      this.busy = false
    })
  }

  updateOrder(){
    this.busy = true
    this.cartService.updateOrder().subscribe(result=>{
      // go away and fetch new orders
      this.profileService.fetchProfile()
      this.busy = false
      this.router.stateService.go('app.update')
    }, error=>{
      alert(error.message)
      this.busy = false
    })
  }

  cancelUpdate(){
    this.cartService.cartOrder = null;
    this.cartService.cartAddress = "";
    this.cartService.cartVisible = false
    this.cartService.empty()
  }

  get cart(){
    return this._cart
  }

  get total(){
    return this._total
  }

  get order(){
    return this._order
  }

  get orderAddress(){
    return this._orderAddress
  }

  get addresses(){
    return this._addresses
  }

  selectedDelivery(id){
    return id == this._selectedAddress
  }

  selectDelivery(id){
    this._selectedAddress = id
  }

}
