import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import { ProfileService } from '../profile.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

  constructor(private cart: CartService, private profileService: ProfileService) { }
  private subscriptions:Array<Subscription> = []

  private _orders = []
  private _draftOrders = []

  ngOnInit() {

    this.cart.cartVisible = false;

    this.subscriptions.push(this.profileService.profile.subscribe(profile=>{
      //console.log(profile)
      this._orders = profile.orders
      if(profile.draftOrders)
        this._draftOrders = profile.draftOrders
    }))

  }

  get orders(){
    return this._orders
  }

  get draftOrders(){
    return this._draftOrders
  }

  ngOnDestroy(){
    this.subscriptions.forEach((item:Subscription)=>{
      item.unsubscribe()
    })
  }

}
