import { Injectable } from '@angular/core';
import Client from 'shopify-buy';
import { BehaviorSubject } from 'rxjs'
import { _ } from 'underscore'
import { environment } from 'src/environments/environment';

const client = Client.buildClient({
  domain: 'white-star-coffee.myshopify.com',
  storefrontAccessToken: environment.shopify
});


@Injectable({
  providedIn: 'root'
})
export class ShopifyService {

  constructor() {}
  private _collection: BehaviorSubject<string> = new BehaviorSubject<string>('')
    
  get collectionObservable(){
    return this._collection
  }
  
  set collection(collection){
    //console.log(collection,'collection in service')
    this._collection.next(collection)
  }

  get products(){
   // console.log(this._collection.value,'this collection')
    const collectionId = this._collection.value
    // client.collection.fetchAllWithProducts().then((collections) => {
    //   console.log(collections, 'collections')
    // })
    return client.collection.fetchWithProducts(collectionId)
  }

  
}
