import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MembrsService } from '@nakedcreativity/membrs-angular-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  year: number = new Date().getFullYear();
  @Output() changeState = new EventEmitter<boolean>();

  constructor(private membrs: MembrsService) { }

  ngOnInit() {

    
  }

  logout(){
    // this.membrs.logout().then(result=>{
    //   console.log(result)
    // })

    localStorage.removeItem('membrs')
    window.location.href = environment.membrs.login
  }

  onClick(){
    this.changeState.emit(true);
  }

}
