import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  constructor(private fb: FormBuilder,public activeModal: NgbActiveModal) { }

  public addressForm: FormGroup
  @Input() public address;

  ngOnInit(): void {
    this.addressForm = this.fb.group({
      company: this.fb.control('', Validators.required),
        address1: this.fb.control('', Validators.required),
        address2: this.fb.control(''),
        city: this.fb.control('', Validators.required),
        countryCodeV2: this.fb.control('', Validators.required),
        zip: this.fb.control('', Validators.required)
    })

    if(this.address)
      this.addressForm.patchValue(this.address)

    //console.log('address input',this.address)
  }

  save(){
    //console.log(this.addressForm.value)
    //console.log(this.address)
    this.activeModal.close({
      id: this.address?this.address.id:null,
      address: this.addressForm.value
    })
  }

  cancel(){
    this.activeModal.close();
  }

}
