import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { UIRouter } from '@uirouter/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgBusyModule } from '@nakedcreativity/ng-busy'
import { MembrsModule } from '@nakedcreativity/membrs-angular-helper';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { ReplacePipe } from './replace.pipe';

// import { MembrsService, checkPermissionResolve, reissueResolve, checkLoginResolve } from './membrs'
import { AppComponent } from './app.component';
import { APP_STATES } from './app.states';

import { SidebarComponent } from './sidebar/sidebar.component';
import { ProfileComponent } from './profile/profile.component';
import { CartComponent } from './cart/cart.component';
import { LayoutComponent } from './layout/layout.component';
import { AddressLookupComponent } from './address-lookup/address-lookup.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { OrderComponent } from './order/order.component';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './product/product.component';
import { PageTitleComponent } from './page-title/page-title.component';


import { ShopifyService } from './shopify.service'
import { CartService } from './cart.service'
import { ProfileService } from './profile.service';
import { TokenInterceptor } from './auth.interceptor';


import { AddressComponent } from './address/address.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { environment } from 'src/environments/environment';
import { MessageComponent } from './message/message.component';
import { UpdateSuccessComponent } from './update-success/update-success.component';
import { CancelSuccessComponent } from './cancel-success/cancel-success.component';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    ProfileComponent,
    CartComponent,
    LayoutComponent,
    AddressLookupComponent,
    OrderHistoryComponent,
    OrderComponent,
    ProductsComponent,
    ProductComponent,
    PageTitleComponent,
    AddressComponent,
    OrderSuccessComponent,
    MessageComponent,
    UpdateSuccessComponent,
    CancelSuccessComponent,
    MobileHeaderComponent,
    ReplacePipe
    ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    NgbModalModule,
    UIRouterModule.forRoot({
      states: APP_STATES, 
      useHash: false,
      otherwise: {state: 'app.profile'}
    }),
    MembrsModule.forRoot({
      api: environment.membrs.api,
      apiProtocol: environment.membrs.apiProtocol,
      graphQL: environment.membrs.graphQL,
      login: environment.membrs.login,
      defaultState: 'app.profile'
    }),
    FormsModule,
    ReactiveFormsModule,
    NgBusyModule
  ],
  providers: [
    CartService,
    ShopifyService,
    ProfileService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


// export function routerConfigFn(router: UIRouter) {
//   const transitionService = router.transitionService;
//   const stateService = router.stateService
//     stateService.defaultErrorHandler(function(error) {
//       console.log(error)
//         // if(error.detail == 'NOT_LOGGED_IN')
//         //     window.location.href = environment.membrs.login;
//         //     //console.log(MembrsSettings)
//         // if(error.detail == 'INSUFFICIENT_PERMISSIONS')
//         //     stateService.go('dashboard')
//     });
// }


