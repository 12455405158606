import { Ng2StateDeclaration, eq } from '@uirouter/angular';
//import { ErrorComponent } from './shared/error/error.component';
//import { MembrsService, checkPermissionResolve, reissueResolve, checkLoginResolve } from './membrs'
//import { Transition, UIRouter } from '@uirouter/angular'

import { ProfileComponent } from './profile/profile.component';
import { LayoutComponent } from './layout/layout.component';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './product/product.component';
import { OrderHistoryComponent } from './order-history/order-history.component';

import { validateResolve } from '@nakedcreativity/membrs-angular-helper'
import { ProfileService } from './profile.service';
import { OrderSuccessComponent } from './order-success/order-success.component';
import {UpdateSuccessComponent } from './update-success/update-success.component';
import {CancelSuccessComponent } from './cancel-success/cancel-success.component';

const profileResolve = { 
  token: 'profile',
  deps: [ProfileService],
  resolveFn: (profile=>{
    console.log('profile resolve')
    return profile.fetchProfile()
  })
} 


export const appState: Ng2StateDeclaration = {
  name: 'app',
  url:'/',
  abstract: true,
  //redirectTo: 'app.profile',
  component: LayoutComponent,
  resolve: [
    validateResolve,
    profileResolve
  ] 
}

export const profileState: Ng2StateDeclaration = {
    name: 'app.profile',
    url:'profile',
    views:{
      '!$default.main':{
        component: ProfileComponent
      }
    }
  }

  export const coffeeState: Ng2StateDeclaration = {
    name: 'app.coffee',
    url:'coffee',
    views:{
      '!$default.main':{
        component: ProductsComponent
      }
    }
  }

  export const productsState: Ng2StateDeclaration = {
    name: 'app.products',
    url:'products',
    redirectTo: 'app.products.milk',
    views:{
      '!$default.main':{
        component: ProductsComponent
      }
    }
  }

  export const milkState: Ng2StateDeclaration = {
    name: 'app.products.milk',
    url:'/milk',
    views:{
      '!$default.main':{
        component: ProductsComponent
      }
    }
  }

  export const otherState: Ng2StateDeclaration = {
    name: 'app.products.other',
    url:'/other',
    views:{
      '!$default.main':{
        component: ProductsComponent
      }
    }
  }

  // export const papersState: Ng2StateDeclaration = {
  //   name: 'app.products.papers',
  //   url:'/papers',
  //   views:{
  //     '!$default.main':{
  //       component: ProductsComponent
  //     }
  //   }
  // }
  // export const equipmentState: Ng2StateDeclaration = {
  //   name: 'app.products.equipment',
  //   url:'/equipment',
  //   views:{
  //     '!$default.main':{
  //       component: ProductsComponent
  //     }
  //   }
  // }

  export const ordersState: Ng2StateDeclaration = {
    name: 'app.orders',
    url:'order-history',
    views:{
      '!$default.main':{
        component: OrderHistoryComponent
      }
    }
  }

  export const successState: Ng2StateDeclaration = {
    name: 'app.success',
    url: 'order-success',
    views:{
      '!$default.main':{
        component: OrderSuccessComponent
      }
    }
  }

  export const updateState: Ng2StateDeclaration = {
    name: 'app.update',
    url: 'order-update-success',
    views:{
      '!$default.main':{
        component: UpdateSuccessComponent
      }
    }
  }

  export const cancelState: Ng2StateDeclaration = {
    name: 'app.cancel',
    url: 'order-cancel-success',
    views:{
      '!$default.main':{
        component: CancelSuccessComponent
      }
    }
  }



export const APP_STATES = [
    appState,
    profileState,
    coffeeState,
    productsState,
    ordersState,
    otherState,
    milkState,
    //papersState,
    //equipmentState,
    successState,
    cancelState,
    updateState
//   errorState,
//   reissueState
]