import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {

  constructor() { }

  @Input() isCollapsed:boolean
  @Output() toggled = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  onClick() {
    this.isCollapsed = !this.isCollapsed;
    this.toggled.emit(this.isCollapsed);
  }

}
